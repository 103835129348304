@font-face {
    font-family: "serifFont";
    src: url('../assets/fonts/Quattrocento-Regular.woff2')format('woff2');
}
@font-face {
    font-family: 'sansFont';
    src: url('../assets/fonts/QuattrocentoSans-Regular.woff2')format('woff2');
}

//mixins
@mixin center-flex($direction: row,$main: center, $cross: center) {
    display: flex;
    flex-direction: $direction;
    justify-content: $main;
    align-items: $cross;
}
@mixin allSpace() {
    width:100%;
    height: 100%;
}
@mixin mainFont($size: 100%) {
    font-family: serifFont, Times, serif !important;
    font-size: $size;
}
@mixin titleFont($size: 100%) {
    font-family: sansFont, Times, serif !important;
    font-size: $size;
}
//variables
$firstColor: #FFB100;
$secondColor: #9C528B;
$darkColor: #153243;
$lightColor: #EEF0EB;

$lightColorOpacity: #eef0ebe3;
$darkColorShadeOpacity: hsla(200, 29%, 10%, 0.65);

$secondColorShade: hsl(314, 55%, 40%);
$darkColorShade: hsl(202, 30%, 10%);
$lightColorShade:hsl(84, 24%, 70%);

$margenSuperior: 64px;

//REVISAR ESTO
@import '../components/landpageComps/linkCard.scss';

//general
a{
    text-decoration: none;
    color: black;
}
.titles{
    @include titleFont();
}
.general{
    @include mainFont();
    line-height: 1.5;
    letter-spacing: 0.00938em;
}
//navigation bar
.nav-container{
    position: absolute;
    width: 100%;
    z-index: 10;
    @include center-flex();
    background-color: $darkColorShadeOpacity;
    // box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    backdrop-filter: blur( 3px );
}
.nav-list{
    padding: 18px 0;
    @include center-flex(row, center,center);
    gap: 48px;
}
.nav-list-item{
    // padding: 0 18px;
    position: relative;
    color:$lightColor;
    @include titleFont();
    transition: all 0.7s;
    &:hover {
        color: $firstColor;
    }
}
.active_navlink{
    color: $firstColor;
}
.nav-list-item::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    margin: -3px 0;
    background-color: $firstColor;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.7s ease-in-out 0s;
    z-index: 100;
  }
  
  .nav-list-item:hover:before {
    visibility: visible;
    transform: scaleX(1);
  }
//Layout
.main-container{
    height: fit-content;
    position: relative;
}
//landpage
.landpage-section {
    overflow: hidden;
    min-height: 100vh;
    width: 100%;
    @include center-flex();
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(21,50,67,1) 40%); 
}
.card-container {
    width: 100%;
    height: 100vh;
    @include center-flex(row, center, flex-start);
    // gap: 4rem;
    flex-wrap: wrap;
}
.landpage-section-test{
    min-height: 100vh;
    width: 100%;
    background: $lightColor;
    @include center-flex(column, flex-start, center);
}
.lanpage-title{
    position: absolute;
    top: 8%;
    left: 4%;
    @include titleFont();
}
.landpage-screen{
    position: absolute;
    top:5%;
    left: 5%;
    width: 72%;
    aspect-ratio: 16/8;
    overflow: hidden;
}
.landpage-icon{
    width: 100%;
    height: 100%;
    overflow: visible;
}
.landpage-rect{
    stroke-width: 20px;
    stroke-linecap: round;
    fill: transparent;

}
h1{
    color: $secondColor;
    font-size: 80px;
    margin: 0;
}
.subtitle {
    color:$lightColor;
    text-align: start;
    margin-top: 4px;
    margin-left: 6px;
    z-index: 5;
    position: absolute;
    width: 250px;
    @include mainFont();
    font-size: 20px;
    letter-spacing: 2px;
}
.subtitle-bkg{
    color: $lightColor;
    z-index: 1;
    height: 25px;
    width: 225px;
    background: $secondColor;
}
.landpage-nav{
    @include center-flex(column, center, flex-end);
    position: absolute;
    bottom: 48px;
    right: 72px;
    gap: 24px;
    font-size: 350%;
}
.landpage-nav > *{
    border-radius: 10%;
    backdrop-filter: blur( 7px );
    padding: 10px;
}
//about section
.about-section {
    background: rgb(29,71,96);
    background: radial-gradient(circle, rgba(29,71,96,1) 0%, rgba(26,39,47,1) 90%); 
    color: $lightColor;
    @include center-flex();
}
.about-position {
    width: 70%;
    @include center-flex(row, space-around, flex-start);
    z-index: 5;
}
.about-info {
    width: 450px;
    min-height: 380px;
}
.about-skills{
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: $lightColor;
    font-weight: bold;
    padding: 0.7rem;
    border: 3px solid $secondColor;
}
.about-skills-title{
    padding: 0 6px;
    margin-top: -38px !important;
    margin-left: -19px;
    width: fit-content;
    border-radius: 10px;
}
.skills-bg{
    position: absolute;
    top:0;
    left: 0;
    background: rgb(29,71,96);
    background: radial-gradient(circle, rgba(29,71,96,1) 0%, rgba(26,39,47,1) 100%);
    z-index: 0;
    width:100vw;
    height:100vh;

}
.skills-list-title{
    position:relative;
    z-index: 5;
}
.skill-group-card{
    font-size: 200%;//CAMBIAR, ES SOLO TEMPORAL
    color: $darkColor;
    // border: $lightColor 1px solid;
    // border-radius: 10px;
    filter: drop-shadow(10px 10px 10px #000);
    transition: all 0.9s;
}
.active-shadow{
    filter: drop-shadow(35px 15px 35px $secondColor);
}
.skill-detail-card{
    display: inline-block;
    color: $secondColorShade;
    background-color: $lightColorOpacity;
    padding: 4px;
    margin: 4px;
    border-radius: 10px;
    z-index: 1;
}
.skill-name {
    color: black;
}
// contact section
.contact-section {
    @include center-flex();
    background-color: $lightColor;
}
.custom-bkg{
    position: absolute;
    top: 0;
    width: 100%;
    background-color: $darkColor;
}
.top{
    position: absolute;
    height: 0;
    width: 0;
    top: 0;
    // margin-top: $margenSuperior;
    border-left: 50vw solid transparent;
    border-right: 50vw solid transparent;
    border-bottom: 150px solid transparent;
    border-top: 180px  solid $lightColorShade;
    opacity: 0.2;
}
.form-container{
    width: 550px;
    z-index: 1;
}
.contact-text{
    @include mainFont();
    font-weight: bold !important;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    min-height: 50px;
}
.contact-title{
    @include titleFont();
    color: $secondColorShade;
    font-weight: bold !important;
}
.loadIcon{
    animation: rotation 2s infinite linear;
}

.contactListPosition {
    // height: 150px;
    position: absolute;
    right: 0;
    top: calc((100vh - 102px) / 2 );
    padding: 0;
    overflow:hidden;
}

.contactIcons {
    @include center-flex(column, center,center);
}
.copiedAlert{
    position: absolute;
    bottom: 52%;
    right: 68px;
}
//portfolio section
.portfolio-section {
    background-color: $darkColor;
}
.portfolio-upper-section{
    // height: 250px;
    background-color: black;
    @include center-flex();
}
.portfolio-main-img{
    width: 100%;
    height: auto;
}
.portfolio-title{
    color: white;
    font-size: 450%;
}
.portfolio-second-section{
    width: 100%;
    @include center-flex();
}
.projects-container{
    width: 100%;
    @include center-flex(row, flex-start, flex-start);
    flex-wrap: wrap;
}
.project-card {
    width: calc(100% / 4);
    aspect-ratio: 1/1;
    overflow: hidden;
}
.project-description{
    width: 100%;
    height: 100px;
    padding: 0 4px;
    opacity: 0;
    background-color: #000000c5;
    transform: translateY(100px);
    transition: all 0.7s;
}
.project-description > h2,p {
    margin: 0;
}
.project-description > p {
    margin-top: 12px
}

.portada-portfolio {
    background-image: url('../assets/images/portfolio/portadaportfolio.webp');
}
.portada-ingeray {
    background-image: url('../assets/images/portfolio/portadaingeray.webp');
}
.portada-maximiliano{
    background-image: url('../assets/images/portfolio/portadamaximiliano.webp');
}
.portada-also{
    background-image: url('../assets/images/portfolio/portada-also.webp');
}
.project-img{
    position: relative;
    @include allSpace;
    background-size: cover;
    transition: 0.7s all;
    transform: scale(1.1);
    @include center-flex(column, flex-end, flex-start);
}
.project-img:hover {
    transform: scale(1);
    .project-description {
        opacity: 1;
        transform: translateY(0);
        color: $lightColor;
    }
}
.project-modal:focus-visible {
    outline: none;
}
.expanded-card-content{
    background-color: $darkColorShade;
    color: $lightColor;
    padding: 6px !important;
}
.expanded-card-skills{
    font-size: 36px;
    margin-top: 6px;
}
.expanded-card-skills svg{
    margin-right: 7px;
}
.expanded-card-content a {
    color: $firstColor !important;
}
.expanded-card-link{
    color: $firstColor !important;
    text-decoration-color: $firstColor !important;
    transition: all 0.6s;
    &:hover {
        color: $lightColor !important;
        text-decoration-color: $lightColor !important;
    }
}
.card-link{
    text-decoration: none;
    color: black;
    padding-bottom: 12px;
}

//MEDIA QUERY


//media query especial para ajustar las tarjetas expandidas
@media screen and (max-width: 625px) {
    .expanded-project-card{
        max-width: 85vw;
    }
}

@media screen and (max-width: 900px) {

    //landpage section

    .landpage-screen{
        width: 90%;
        overflow: visible;
    }
    h1{
        font-size: 60px;
    }
    .landpage-nav{
        right: 5%;
        bottom: 2%;
    }

    //about section
    .about-section{
        flex-direction: column;
    }
    .about-position {
        flex-direction: column;
        padding-top: 43px;
        padding-bottom: 86px;
        gap: 48px;
        width: 80%;
    }
    .about-skills-position{
        align-self: center;
    }
    .about-info{
        width: 100%;
    }
    /*
    
    contact section

    */
    .contact-section{
        padding-top: 0;
        flex-direction: column;
    }
    .top {
        display: none;
    }
    .contact-content{
        align-self: flex-start;
        padding-top: 48px;
    }
    .form-container{
        width: 80%;
    }
    .contactIcons{
        flex-direction: row;
        padding-top: 36px !important;
    }
    .copiedAlert{
        bottom: 60px;
        right: 1%;
    }
    //portfolio section
    .project-card {
        width: 50%;
    }

    //elementos generales
    .nav-container{
        position: fixed;
        bottom: 0;
    }
    .custom-bkg{
        top: auto;
        bottom: 0 !important;
    }
}
@keyframes rotation {
    from {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(359deg);
    }
}