.linkWrapper{
    width: 50%;
    height: 50%;
}

.linkCard {
    // width: 280px;
    width: 100%;
    height: 100%;
    // aspect-ratio: 2/1;
    background: rgba(238, 240, 235, 0.07);
    // border-radius: 12px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    color: #FFB100;
    font-size: 200%;
    display: flex;
    justify-content: center;
    align-items: center;
}